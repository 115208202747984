<template>
    <BaseLayout>
        <section v-if="categoryGroup != null && categoryGroup.banner_url" class="ol-hero-page">
            <figure class="ol-hero-page__figure">
                <img :src="categoryGroup.banner_url">
            </figure>
        </section>
        <section class="ol-product">
            <div class="ol-wrapper ol-wrapper--large">
                <div v-if="categoryGroup !=null && categoryGroup.hasOwnProperty('r_sub_active') && categoryGroup.r_sub_active.r_subs.length > 0" class="ol-product-type">
                    <div class="ol-product-type__title">TIPE {{categoryGroup.name}} :</div>
                    <div class="ol-product-type__list">
                        <div v-for="subCategory in categoryGroup.r_sub_active.r_subs" :key="subCategory.id" @click="jqProductTypeChange(subCategory.id)" :id="'id_tipe_'+subCategory.id" :class="'product-type-item ol-product-type__list-item '+(subCategory.id===categoryGroup.r_sub_active.r_sub_active.id ? 'active':'')">
                            <div class="ol-product-type__list-item-wrapper">
                                <figure class="ol-product-type__list-item-figure">
                                    <a href="javascript:void(0)">
                                        <img :src="subCategory.image_url">
                                    </a>
                                </figure>
                                <h3 class="ol-product-type__list-item-name">
                                    <a href="javascript:void(0)" :title="subCategory.name">
                                        {{subCategory.name}}
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ol-product-content add-fix">

                    <aside v-if="!loadingSidebar" class="ol-product-content__sidebar">
                        <div class="ol-product-content__sidebar-item">
                            <div class="ol-product-content__sidebar-item-title">URUTKAN</div>
                            <select v-model="filteredBy.type" class="ol-input" style="height:46px">
                                <option value="">Pilih Opsi</option>
                                <option value="terlaris">Terlaris</option>
                                <option value="terbaru">Terbaru</option>
                                <!-- <option value="terpopuler">Terpopuler</option> -->
                                <option value="termurah">Termurah</option>
                                <option value="termahal">Termahal</option>
                            </select>
                        </div>  

                        <a href="javascript:void(0)" @click="jqCloseFilter()" class="ol-filter-close"><i class="ol-fa fas fas fa-times"></i></a>
                        <div class="ol-product-content__sidebar-item" style="display:none;">
                            <div class="ol-product-content__sidebar-item-title" >KATEGORI</div>

                            <div class="ol-product-content__sidebar-item-list">
                                <div v-for="category in filterParameters.categories" :key="category.id">
                                    <div v-if="category.r_sub.length > 0"  class="ol-product-content__sidebar-item-list-item base-list-item" >
                                        <div class="ol-product-content__sidebar-item-list-item-title" @click="toggleMenu('#item-ctg-menu-'+category.id)">{{ category.name }}</div>
                                        <div class="ol-product__filter-list__item-dd " :id="'item-ctg-menu-'+category.id" style="display:none">
                                            <label class="ol-product-content__sidebar-item-list-item-link sapi"  v-for="subCategory in category.r_sub" :key="subCategory.id" :id="'sub-item-menu-'+subCategory.id">
                                                <input class="ol-input--radio-check sapi " v-model="filteredBy.categories" :value="subCategory.id" type="checkbox" >
                                                <!-- <span>{{subCategory.name}}</span><u>{{subCategory.product_count}}</u> -->
                                                <u><span>{{subCategory.name}}</span></u>
                                                <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio " property="checked"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div v-for="category in filterParameters.categories" :key="category.id+'-parent'">
                                    <div v-if="category.r_sub.length < 1" class="ol-product__filter-list__item-dd " :id="'item-ctg-menu-'+category.id" >
                                        <label class="ol-product-content__sidebar-item-list-item-link sapi" >
                                            <input class="ol-input--radio-check sapi " v-model="filteredBy.categories" :value="category.id" type="checkbox" >
                                            <u><span>{{category.name}}</span></u>
                                            <!-- <span>{{category.name}}</span><u>{{category.product_count}}</u> -->
                                            <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio " property="checked"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="ol-product-content__sidebar-item">
                            <div class="ol-product-content__sidebar-item-title">FILTER</div>
                            <div class="ol-product-content__sidebar-item-list">
                                <div class="ol-product-content__sidebar-item-list-item" style="display:none;">
                                    <div class="ol-product-content__sidebar-item-list-item-title" @click="toggleMenu('#item-price-menu')">HARGA</div>
                                    <div id="item-price-menu">
                                        <div class="price-range-slider">
                                            <input v-model="filteredBy.price_min" class="input_range_harga ol-input" type="number" name="range_min" id="range_min" placeholder="0" >
                                            -
                                            <input v-model="filteredBy.price_max" class="input_range_harga ol-input" type="number" name="range_max" id="range_max" placeholder="99999999">
                                            <button @click="getProducts(true)" class="ol-btn ol-btn--block">Set Harga</button>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="filterParameters.brands.length>0" class="ol-product-content__sidebar-item-list-item">
                                    <div class="ol-product-content__sidebar-item-list-item-title" @click="toggleMenu('#item-brands-menu')">BRAND</div>
                                    <div class="ol-product__filter-list__item-dd" id="item-brands-menu" style="display:block;">
                                        <input name="perpage" value="10" id="item_perpage" type="hidden" >
                                        <label v-for="brand in filterParameters.brands" :key="brand.id" class="ol-product-content__sidebar-item-list-item-link" >
                                            <input v-model="filteredBy.brands" class="ol-input--radio-check"  :value="brand.id" type="checkbox">
                                            <span>{{brand.id}}</span>
                                            <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio"></i>
                                        </label>

                                        <!-- <label v-for="list in listBrand" :key="list" class="ol-product-content__sidebar-item-list-item-link" >
                                            <input v-model="filteredBy.brands" class="ol-input--radio-check"  :value="list" type="checkbox">
                                            <span>{{list}}</span>
                                            <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio"></i>
                                        </label> -->
                                    </div>
                                </div>

                                <!-- <div v-if="filterParameters.colors.length>0" class="ol-product-content__sidebar-item-list-item">
                                    <div class="ol-product-content__sidebar-item-list-item-title"  @click="toggleMenu('#item-colors-menu')">WARNA</div>
                                    <div id="item-colors-menu">
                                        <a v-for="color in filterParameters.colors" :key="color.code" @click="colorChanges(color.id)" :id="'color_'+color.id" :data-name="color.name" class="ol-product-content__sidebar-item-list-item-color filter-colors" :title="color.name" :tag="color.name" :style="'background:linear-gradient('+color.code+');'"></a>
                                    </div>
                                </div>

                                <div v-if="filterParameters.materials.length>0"  class="ol-product-content__sidebar-item-list-item">
                                    <div class="ol-product-content__sidebar-item-list-item-title" @click="toggleMenu('#item-materials-menu')">MATERIAL</div>
                                    <div id="item-materials-menu"> 
                                        <label class="ol-product-content__sidebar-item-list-item-link" v-for="material in filterParameters.materials" :key="material.id">
                                            <input  class="ol-input--radio-check" v-model="filteredBy.materials" :value="material.id" type="checkbox" >
                                            <span>{{material.name}}</span><u></u>
                                            <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio"></i>
                                        </label>
                                    </div>
                                </div> -->
                                <div class="ol-product-content__sidebar-item-list-item">
                                    <button class="ol-btn ol-btn--block" @click="removeFilter()">Atur Ulang Pencarian</button>
                                </div>
                            </div>
                        </div>  
                    </aside>
                    <SidebarLoader v-if="loadingSidebar"/>

                    <div  class="ol-product-content__center">
                        <div class="row no-gutters">
                            <div class="col-6">
                                <h1 style="font-weight: 700;font-size: 18px;color: #000000;">{{ formatString(this.$route.params.slug) }}</h1>
                            </div>
                            <div class="col-6" style="text-align: right;align-content: center;" >
                                <div class="ol-breadcrumb" style="padding-bottom:0px;">
                                <a href="/" class="ol-breadcrumb__link">Beranda</a> 
                                >
                                <a href="/products" class="ol-breadcrumb__link">Produk</a>
                                >
                                <a :href="this.$route.params.slug" class="ol-breadcrumb__link">
                                    {{ formatString(this.$route.params.slug) }}
                                </a> 
                            </div>
                            </div>
                        </div>
                        <div class="break-16"></div>
                        <div v-if="!loading" class="ol-product-content__center-filter add-fix">
                            <div v-if="list.total > 0" class="ol-product-content__center-filter-item ol-product-content__center-filter-total">
                                <strong>{{list.total}}</strong> PRODUK DITEMUKAN
                            </div>
                            <div v-if="list.total > 0" class="ol-product-content__center-filter-item ol-product-content__center-filter-show">
                                <strong>TAMPILKAN</strong>
                                <div>
                                    <a href="javascript:void(0)" @click="getProducts(true,12)" class="'ol-product-content__center-filter-show-link " :style="(filteredBy.per_page == 12 ? 'text-decoration: underline;':'')+' margin-left:5px;'">12</a>
                                    <a href="javascript:void(0)" @click="getProducts(true,24)" class="'ol-product-content__center-filter-show-link " :style="(filteredBy.per_page == 24 ? 'text-decoration: underline;':'')+' margin-left:5px;'">24</a>
                                    <a href="javascript:void(0)" @click="getProducts(true,36)" class="'ol-product-content__center-filter-show-link " :style="(filteredBy.per_page == 36 ? 'text-decoration: underline;':'')+' margin-left:5px;'">36</a>
                                </div>
                            </div>
                        </div>
                        <ProductList :products="products" /> 
                        <div v-if="!loading && list.total < 1"  style="width:100%;margin-top:0px" class="ol-product-content__center-filter-item ol-product-content__center-filter-total ol-cart-floating__empty">
                            <div class="row no-gutters">
                                <div class="col-4">
                                    <img :srcset="assets('empty-cart.svg')" alt="Keranjang Belanja" title="Keranjang Belanja" style="width:70%"/>
                                </div>
                                <div class="col-8" style="text-align:left;">
                                    <div class="ol-cart-floating__empty-label" style="margin-bottom:0px;">
                                        Opps, produk tidak ditemukan
                                    </div>
                                    <span>Coba kata kunci lain atau lihat produk rekomendasi di bawah.</span>
                                </div>
                            </div>
                        </div>
                        <center>
                            <br>
                            <button v-show="!loading && list.more" type="button" class="ol-btn" @click="getProducts()">Lebih Banyak Produk <i class="fas fa-angle-down ol-fa"></i> </button>
                        </center>
                        <div v-if="!loading && list.total < 1" >
                            <div class="ol-section-title">
                                <span>Rekomendasi produk</span>
                                    <i></i>
                            </div>
                            <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loadingRelated"/>
                            <ProductList :products="related" v-if="!loadingRelated" />
                        </div>
                    </div>
                    <ProductLoader :page="filteredBy.page" :qty="filteredBy.per_page" v-if="loading"/>
                </div>
            </div>
        </section>
        <div @click="jqOverlayFilter()" class="ol-overlay-filter"></div>
        <a href="javascript:void(0)" @click="jqOpenFilter()" class="ol-filter"><i class="ol-fa fas fa-filter"></i></a>
    </BaseLayout>
</template>

<script>
import VueMeta from 'vue-meta';
import {mapActions,mapGetters} from "vuex";
import BaseLayout from "@/components/landing/BaseLayout.vue"
import ProductList from "@/components/product/ProductList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
import SidebarLoader from '@/components/loading/product/Sidebar.vue'
import CarouselProduct from '@/components/home/CarouselProduct.vue'
export default {
    name:"CategoryFind",
    components:{
        BaseLayout,
        ProductList,
        ProductLoader,
        SidebarLoader,
        CarouselProduct
    },
    data(){
        return {
            loading:true,
            loadingSidebar:true,
            loadingRelated: false,
            list:{
                more:false,
                total:0,
            },
            statusReset:false,
            filteredBy:this.$store.state.product.list.filtered,
            resultSlug: "",
            stringAktif: ""
        }
    },
    computed:{
        ...mapGetters("product/list",["products","categories","filterParameters","filtered","categoryGroup","copiedFilter"]),
        ...mapGetters("product/detail", ["related"]),
        listBrand() {
            const groupedData = this.groupBy(this.products, 'brand');
            const brand = Object.keys(groupedData);
            console.log(brand);
            return brand;
        }
    },
    methods:{
        ...mapActions("product/list",["fetchProducts","fetchFilterParameters"]),
        ...mapActions("product/detail", ["fetchRelated"]),
        formatString(input) {
            // Replace hyphens with spaces
            let spaced = input.replace(/-/g, ' ');

            // Capitalize the first letter of each word
            let capitalized = spaced.replace(/\b\w/g, function(char) {
                return char.toUpperCase();
            });

            return capitalized;
        },
        retrieveCopiedFilter(){
            this.statusReset = true;
            let query = _.mapKeys(queryString.parse(this.$route.params.filter), (value, key) => key.replace("[]", "") );

            query.categories = [15];
            query.materials = _.isArray(query.materials) ? query.materials : _.compact([query.materials]);
            query.colors = _.isArray(query.colors) ? query.colors : _.compact([query.colors]);
            query.brands = _.isArray(query.brands) ? query.brands : _.compact([query.brands]);

            query.per_page = this.filteredBy.per_page;
            query.page = 1;
            // console.log("query",query);
            this.filteredBy = query;
            
            this.$store.commit("product/list/setCopiedFilter", false);
            // console.log("copiedFilter",this.copiedFilter)
        },
        groupBy(data, key) {
            return data.reduce((result, currentValue) => {
                // mendapatkan nilai kunci untuk grup
                const groupKey = currentValue[key];
                
                // membuat grup jika belum ada
                if (!result[groupKey]) {
                result[groupKey] = [];
                }
                
                // menambahkan nilai ke grup
                result[groupKey].push(currentValue);
                
                return result;
            }, {});
        },
        async getProducts(firstPage = false,per_page=null){
            this.loading = true;
            if(firstPage) this.filteredBy.page = 1;            
            if(per_page != null) this.filteredBy.per_page = per_page;

            let slug = this.$route.params.slug;
            let resultSlug = slug.replace(/-/g, " ");
            // this.filteredBy.q = resultSlug; 
            
            // this.filteredBy.q = resultSlug; 
            this.filteredBy.q = resultSlug; 
            if(this.copiedFilter && this.$route.params.filter) this.retrieveCopiedFilter();
            // this.retrieveCopiedFilter();
            
            this.$store.commit("product/list/setFiltered",this.filteredBy);
            
            const results = await this.fetchProducts();
            this.statusReset = false;
            this.loading = false;
            if(results.success){
                this.list.total = results.data.total; 
                this.list.more = results.data.pagination.more;
                if(this.list.more) {
                    this.filteredBy.page=this.filteredBy.page+1;
                    this.$store.commit("product/list/setFiltered",{page:this.filteredBy.page});
                }

                if(results.data.total == 0){
                    this.$router.push("/404");
                    return false;
                }
            }else{
                this.$toast.open({ message: "Gagal mendapatkan data produk", type: "error", duration: 3000, })
            }
            this.openParentCategory();
        },
        toggleMenu(selectorName){
            $(selectorName).slideToggle("fast");
        },
        toggleMenuWithSelector(targetSelector){
            targetSelector.slideToggle("fast");
        },
        colorChanges(colorID){
            let colors = this.filteredBy.colors;
            if($("a#color_"+colorID).toggleClass("active").hasClass('active')){
                colors.push(colorID)
            }else{
                colors = _.filter(colors, (color) => color != colorID);
            }
            this.filteredBy.colors=colors;
        },
        jqProductTypeChange(subCategory_id){
            // console.log("subCategory_id",subCategory_id);
            $("div.product-type-item").removeClass("active");
            $("div.product-type-item#id_tipe_"+subCategory_id).addClass("active");

            this.filteredBy.categories = [this.categoryGroup.r_sub_active.id,subCategory_id];
        },
        jqOpenFilter(){
            $(".ol-product-content__sidebar").hasClass("ol-product-content__sidebar--show") ? ($(".ol-product-content__sidebar").removeClass("ol-product-content__sidebar--show"), $(".ol-product-content__sidebar").animate({
                    left: "-999px"
                }, 300), $(".ol-overlay").fadeOut(300)) : ($(".ol-product-content__sidebar").addClass("ol-product-content__sidebar--show"), $(".ol-product-content__sidebar").animate({
                    left: "0"
                }, 300), $(".ol-overlay-filter").fadeIn(300))
        },
        jqCloseFilter(){
            $(".ol-product-content__sidebar").hasClass("ol-product-content__sidebar--show") ? ($(".ol-product-content__sidebar").removeClass("ol-product-content__sidebar--show"), $(".ol-product-content__sidebar").animate({
                            left: "-999px"
                        }, 300), $(".ol-overlay-filter").fadeOut(300)) : ($(".ol-product-content__sidebar").addClass("ol-product-content__sidebar--show"), $(".ol-product-content__sidebar").animate({
                            left: "0"
                        }, 300), $(".ol-overlay-filter").fadeOut(300))
        },
        jqOverlayFilter(){
            $(".ol-product-content__sidebar").hasClass("ol-product-content__sidebar--show") ? ($(".ol-product-content__sidebar").removeClass("ol-product-content__sidebar--show"), $(".ol-product-content__sidebar").animate({
                            left: "-999px"
                        }, 300), $(".ol-overlay-filter").fadeOut(300)) : ($(".ol-product-content__sidebar").addClass("ol-product-content__sidebar--show"), $(".ol-product-content__sidebar").animate({
                            left: "0"
                        }, 300), $(".ol-overlay-filter").fadeIn(300))
        },
        jq(){
            let jq= {
                productTypes:()=>{
                        $(".ol-product-type__list").slick({
                            slidesToScroll: 1,
                            slidesToShow: 6,
                            dots: !1,
                            autoplay: !1,
                            autoplaySpeed: 5e3,
                            arrows: !0,
                            pauseOnFocus: !1,
                            responsive: [{
                                breakpoint: 961,
                                settings: {
                                    slidesToShow: 5
                                }
                            }, {
                                breakpoint: 769,
                                settings: {
                                    slidesToShow: 4
                                }
                            }, {
                                breakpoint: 481,
                                settings: {
                                    slidesToShow: 3
                                }
                            }, {
                                breakpoint: 361,
                                settings: {
                                    slidesToShow: 2
                                }
                            }]
                        });
                },
                scrolling2top:()=>{
                    // if( (this.filtered.type || this.statusReset) && !this.categoryGroup && this.filtered.page == 1) $("a#a-footer-to-top").trigger("click");
                },
                filterMobile:()=>{
                    if ($(".ol-filter").length) {
                        var e = function() {
                            50 < $(window).scrollTop() ? $(".ol-filter").addClass("show") : $(".ol-filter").removeClass("show")
                        };
                        e(), $(window).on("scroll", function() {
                            e()
                        })
                    }
                },
                init:()=>{
                    jq.productTypes();
                    jq.scrolling2top();
                    jq.filterMobile();
                }
            };
            jq.init();
        },
        removeFilter(){
            $("a.filter-colors").removeClass("active");
            this.statusReset = true;
			this.$store.commit("product/list/resetFilter",true);
            this.getProducts(true);
        },
        watchAction(value){
            // this.stateless = false;
            if(!this.statusReset) this.getProducts(true);
            // console.log(value)
        },
        openParentCategory(){
            this.filteredBy.categories.forEach(subId => {
                $("input:checkbox[value='"+subId+"']").parents(".ol-product__filter-list__item-dd").slideDown("fast");
            });  
        },
        updateMetaInfo() {
            let slug = this.$route.params.slug;
            this.resultSlug = slug.replace(/-/g, " ").replace(/(^\w|\s\w)/g, m => m.toUpperCase());

            const bulanIndonesia = [
                "Januari", "Februari", "Maret", "April", "Mei", "Juni",
                "Juli", "Agustus", "September", "Oktober", "November", "Desember"
            ];

            const tanggalSekarang = new Date();
            const namaBulan = bulanIndonesia[tanggalSekarang.getMonth()];
            const tahun = tanggalSekarang.getFullYear();
            this.stringAktif = `${namaBulan} ${tahun}`;
            },
        getMetaTitle() {
            switch (this.$route.params.slug) {
                case 'tempat-penyimpanan':
                return "Jual Tempat Penyimpanan Barang | MORE Furniture";
                case 'lemari-pakaian':
                return "Jual Lemari Pakaian Minimalis | MORE Furniture";
                case 'rak-tv':
                return "Jual Rak TV Minimalis Modern | MORE Furniture";
                case 'meja-makan':
                return "Jual Meja Makan Minimalis | MORE Furniture";
                case 'meja-kerja':
                return "Jual Meja Kerja Minimalis | MORE Furniture";
                case 'meja-rias':
                return "Jual Meja Rias Minimalis | MORE Furniture";
                case 'meja-anak':
                return "Jual Meja Belajar Anak Minimalis | MORE Furniture";
                case 'sofa-bed':
                return "Jual Sofa Bed Minimalis | MORE Furniture";
                case 'kursi':
                return "Jual Kursi Minimalis | MORE Furniture";
                case 'furnitur-dapur':
                return "Jual Furniture Dapur Minimalis | MORE Furniture";
                case 'springbed-kasurbusa':
                return "Jual Springbed & Kasur Busa | MORE Furniture";
                case 'laundry':
                return "Jual Furniture Ruang Laundry | MORE Furniture";
                case 'laci-susun':
                return "Jual Laci Susun | MORE Furniture";
                case 'kasur':
                return "Jual Kasur | MORE Furniture";
                case 'lemari-buku-anak':
                return "Jual Lemari Buku Anak | MORE Furniture";
                case 'lemari-pakaian-anak':
                return "Jual Lemari Pakaian Anak | MORE Furniture";
                case 'kursi-anak':
                return "Jual Kursi Belajar Anak | MORE Furniture";
                case 'meja-anak':
                return "Jual Meja Belajar Anak Minimalist | MORE Furniture";
                case 'meja-makan':
                return "Jual Meja Makan Minimalist | MORE Furniture";
                case 'kursi-makan':
                return "Jual Kursi Makan Minimalis | MORE Furniture";
                case 'kabinet-dapur':
                return "Jual Kabinet Dapur Minimalis | MORE Furniture";
                case 'rak-piring':
                return "Jual Rak Piring Minimalis | MORE Furniture";
                case 'kursi-tamu':
                return "Jual Kursi Tamu Minimalis | MORE Furniture";
                case 'meja-tamu':
                return "Jual Meja Tamu Minimalis | MORE Furniture";
                case 'meja-tv':
                return "Jual Meja TV Minimalis Modern | MORE Furniture";
                case 'rak-buku':
                return "Jual Rak Buku Minimalis Modern | MORE Furniture";
                case 'rak-sepatu':
                return "Jual Rak Sepatu Minimalis | MORE Furniture";
                case 'kursi-kerja':
                return "Jual Kursi Kerja | MORE Furniture";
                case 'meja-kerja':
                return "Jual Meja Kerja Kantor Minimalis | MORE Furniture";
                case 'rak-tempat-penyimpanan':
                return "Jual Rak Penyimpanan Barang Serbaguna | MORE Furniture";
                default:
                return `Jual ${this.resultSlug} Terbaik ${this.stringAktif} | MORE Furniture`;
            }
            },
        getMetaDescription() {
            switch (this.$route.params.slug) {
                case 'tempat-penyimpanan':
                return "Beli Tempat Penyimpanan Barang minimalis, modern & aesthetic dari Olympic dan Olymplast. Cocok untuk ruang tamu & lainnya. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'lemari-pakaian':
                return "Beli promo Lemari Pakaian Minimalis, modern & aesthetic dari Olympic dan Olymplast. Varian 2 pintu, 3, 4, 5 pintu dan slider.☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'rak-tv':
                return "Beli promo Rak TV desain minimalis dan modern yang kokoh dengan material terbaik. Pilihan warna mulai putih, coklat kayu. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'meja-makan':
                return "Beli promo Meja Makan minimalis 1 set dengan 4 kursi kuat. Cocok untuk mengisi dapur dan ruang makan segala desain interior. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'meja-kerja':
                return "Beli promo Meja Kerja Minimalis yang sudah dilengkapi dengan colokan, luas meja lebar, kokoh, dan laci penyimpanan modern. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'meja-rias':
                return "Beli promo Meja Rias Minimalis Modern yang dilengkapi dengan cermin, kursi, laci penyimpanan, tempat makeup dan lampu. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'meja-anak':
                return "Beli promo Meja Belajar Anak Minimalis dengan dilengkapi rak buku, bisa dilipat, laci penyimpanan dan ketahanan yang kokoh. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'sofa-bed':
                return "Beli promo Sofa Bed Minimalis untuk Ruang Tamu dilengkapi dengan tempat penyimpanan, sandaran, busa empuk dan nyaman. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'kursi':
                return "Beli promo Kursi Kayu & Plastik Minimalis yang cocok untuk ruang tamu, teras rumah, kamar tidur maupun dapur dengan kualitas terbaik. ☑️Gratis Ongkir 100%  ☑️Bergaransi";
                case 'furnitur-dapur':
                return "Beli promo berbagai furnitur untuk Dapur seperti kabinet atas, kabinet bawah, kabinet gantung, rak susun, rak piring. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'springbed-kasurbusa':
                return "Beli promo Springbed & Kasur Busa dengan kain rajut lembut, teknologi dari Jerman yang memberikan pengalaman tidur terbaik. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'laundry':
                return "Beli promo furniture ruang Laundry terbaik seperti keranjang pakaian, meja setrika, trolley serbaguna dan rak mesin cuci. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'laci-susun':
                return "Beli promo laci plastik & kayu susun dengan variasi 2/3/4/5 susun yang berdesain minimalis. Cocok untuk semua ruangan. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'kasur':
                return "Beli promo Kasur Springbed, Kasur Lipat dan Kasur Busa teknologi Jerman membuat tidur berkualitas. Tersedia ukuran 1,2,3,4. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'furnitur-dapur':
                return "Beli promo Lemari Buku Anak berdesain minimalis & modern yang membuat ruangan semakin rapi dan anak berprestasi. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'lemari-buku-anak':
                return "Beli promo Lemari Buku Anak berdesain minimalis & modern yang membuat ruangan semakin rapi dan anak berprestasi. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'lemari-pakaian-anak':
                return "Beli promo Lemari Pakaian Anak untuk perempuan & laki-laki dengan desain minimalis 2/3/4 pintu untuk kamar tidur lebih rapi. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'kursi-anak':
                return "Beli promo kursi belajar anak dengan berbagai karakter kartun yang lucu seperti disney tsum-tsum, frozen, iron man. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'meja-anak':
                return "Beli promo meja belajar anak minimalist dengan laci penyimpanan yang membuat semua barang terorganisir dengan rapi. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'meja-makan':
                return "Beli promo meja makan minimalist yang dilengkapi dengan kursi 4 dengan warna hitam, putih, cream dan coklat kayu. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'kursi-makan':
                return "Beli promo kursi makan minimalis dengan bahan berkualitas (kayu dan plastik) dan mempunyai gaya interior modern dan simple. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'kabinet-dapur':
                return "Beli promo kabinet dapur minimalis bawah dan atas yang beraneka ragam sesuai dengan ukuran dapur segala bentuk. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'rak-piring':
                return "Beli promo rak piring minimalis berbahan plastik yang ditempatkan disebelah wastafel. Cocok untuk penyimpanan piring, gelas. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'kursi-tamu':
                return "Beli promo kursi tamu minimalis modern untuk ruang tamu, ruang nonton dan ruang kerja yang membuat nyaman. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'meja-tamu':
                return "Beli promo meja tamu minimalis yang cocok untuk ruang tamu berbagai gaya interior. Berbahan kayu dengan model menarik. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'meja-tv':
                return "Beli promo meja TV dengan gaya minimalis modern yang bisa juga untuk menyimpan buku, pot tanaman, lampu dan console. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'rak-buku':
                return "Beli promo rak buku minimalis modern klasik membuat barang menjadi rapi bisa ditempatkan di dinding. Bahan plastik dan kayu. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'rak-sepatu':
                return "Beli promo rak sepatu minimalis dengan bahan plastik, kayu dan besi. Cocok untuk berbagai gaya interior. Sepatu jadi rapi. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'kursi-kerja':
                return "Beli promo kursi kerja kantor maupun ruang kerja startup. Cocok untuk staff maupun bos. Berbahan plastik, kayu dan besi. ☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'meja-kerja':
                return "Beli promo meja kerja kantor minimalis dan modern. Dilengkapi dengan colokan, laci penyimpanan dan tempat penyimpanan.☑️Gratis Ongkir 100% ☑️Bergaransi";
                case 'rak-tempat-penyimpanan':
                return "Beli promo laci rak penyimpanan barang serbaguna yang membuat barang rapi dan mudah dicari. Cocok untuk semua gaya interior.☑️Gratis Ongkir 100% ☑️Bergaransi";
                default:
                return `Temukan daftar harga furniture ${this.resultSlug} terbaru ${this.stringAktif} berkualitas dan terlengkap. Gratis ongkir 100% dan Garansi Produk 2x24 Jam.`;
            }
        }
    },
    watch:{
        '$route.params.slug': 'updateMetaInfo',
        'filtered.q':function(value){this.watchAction(value)},
        'filteredBy.category_group':function(value){
            if(value.length <= 0) return;
            this.watchAction(value)
        },
        'filteredBy.brands':function(value){
            if(value.length <= 0) return;
            this.watchAction(value)
        },
        'filteredBy.materials':function(value){
            if(value.length <= 0) return;
            this.watchAction(value)
        },
        'filteredBy.colors':function(value){
            if(value.length <= 0) return;
            this.watchAction(value)
        },
        'filteredBy.categories':function(value){
            if(value.length <= 0) return;
            this.watchAction(value);
        },
        'filteredBy.type':function(value){this.watchAction(value)},
        // $route(to, from) {
            // console.log(to);
            // console.log(from);
            // react to route changes...
        // }
    },
    created(){
        this.$store.commit('transaction/checkout/setOrder', null);
    },
    async mounted(){
        this.fetchFilterParameters(() => this.loadingSidebar = false);
        await Promise.all([
            this.getProducts(true),
            this.fetchRelated(),
        ]);
        
        this.updateMetaInfo();
    },
    beforeUpdate(){
        if($('.ol-product-type__list').hasClass("slick-initialized slick-slider")){
            $('.ol-product-type__list').slick('unslick');
        }
    },
    updated(){
        this.jq();
    },
    metaInfo() {
        return {
            title: this.getMetaTitle(),
            meta: [
                { name: 'description', content: this.getMetaDescription() },
                { name: 'robots', content: 'index,follow' },
                { property: 'og:title', content: this.getMetaTitle() },
                { property: 'og:url', content: window.location.origin + this.$route.fullPath },
                { property: 'og:description', content: this.getMetaDescription() },
                { property: 'og:site_name', content: 'More Furniture' },
                { property: 'og:type', content: 'website' }
            ],
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        };
    }
}
</script>

<style>
</style>